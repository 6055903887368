import React from 'react';
import BusinessCard from '../../fields/BusinessCard';
import DateSpan from '../../fields/DateSpan';
import Content from '../../fields/Content';
import Image from '../../fields/Image';
import MetaComponent from '../../devtools/MetaComponent';
import Body from '../../fields/Body';

const EventDetail = (props) => {
	const data = props.data.eventItem.edges[0].node;
	const entity_data = data.r.entity_id;
	const organizer = entity_data.r.organizer;
	const media = entity_data.r.media;
	const lodge = props.lodge;

	let jsonLdData = {
		'@context': 'https://schema.org',
		'@type': 'Event',
		name: `${entity_data.title}`,
		startDate: `${entity_data.field_dates.start}`,
		endDate: `${entity_data.field_dates.end}`,
		eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
		eventStatus: 'https://schema.org/EventScheduled',
		location: {
			'@type': 'Place',
			name: `${lodge.label}`,
			address: {
				'@type': 'PostalAddress',
				streetAddress: `${lodge.field_address.addr1}`,
				addressLocality: `${lodge.field_address.city}`,
				postalCode: `${lodge.field_address.zip}`,
				addressRegion: `${lodge.field_address.state}`,
				addressCountry: 'US',
			},
		},
		image: `${process.env.GATSBY_FILES_URL}` + `${media.r.image.uri.url}`,

		description: `${entity_data.summary}`,
		offers: {
			'@type': 'Offer',
			url: '',
			price: '',
			priceCurrency: 'USD',
			availability: '',
			validFrom: '',
		},
		performer: {
			'@type': 'PerformingGroup',
			name: '',
		},
		organizer: {
			'@type': 'Organization',
			name: `${lodge.label}`,
			url: `${lodge.field_website.url}`,
		},
	};

	return (
		<div className='bg-white overflow-hidden'>
			<MetaComponent jsonLd={jsonLdData} />
			<div className='relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
				<div className='mx-auto text-base max-w-prose lg:max-w-none pb-6'>
					<div className='flex flex-row items-center'>
						<h1 className='font-title my-2 text-2xl md:text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
							{entity_data.title}
						</h1>
						<div className='hidden md:flex ml-10'>
							<span className='font-body px-6 py-2 text-base leading-5 font-semibold rounded-full bg-ap-green-light text-ap-green-dark text-center place-self-center z-10'>
								{entity_data.r.field_audience.name}
							</span>
						</div>
					</div>
					<div className='my-1 md:my-6'>
						<DateSpan
							startDate={entity_data.field_dates.start}
							endDate={entity_data.field_dates.end}
							textClasses='font-body font-semibold text-sm sm:my-1 lg:my-0 md:text-xl text-ap-orange'
							displayFormat='col'
						/>
					</div>
					<div className='flex md:hidden mt-4'>
						<span className='font-body px-4 py-1 text-sm leading-5 font-semibold rounded-full bg-ap-green-light text-ap-green-dark'>
							{entity_data.r.field_audience.name}
						</span>
					</div>
				</div>
				<div className='lg:grid lg:grid-cols-2 lg:gap-8'>
					<div className='relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2'>
						{media && (
							<div className='relative text-base mx-auto max-w-prose lg:max-w-none'>
								<figure>
									<div className='relative pb-9/12 lg:pb-0'>
										<Image
											image={media.r.image}
											alt={entity_data.title}
											className='rounded-lg shadow-lg object-cover object-center inset-0 w-full h-full lg:static lg:h-auto'
											sizes='600w'
										/>
									</div>
								</figure>
							</div>
						)}
					</div>

					<div>
						{entity_data.short_title && (
							<div className='font-body text-gray-700 mb-8 text-base md:text-lg leading-5 font-semibold italic'>
								{entity_data.short_title}
							</div>
						)}

						<div className='text-base max-w-prose mx-auto lg:max-w-none'>
							<div className='font-body text-base prose md:text-lg md:leading-7 text-gray-900 mb-5'>
								{/* {Parser(entity_data.body.processed)} */}
								<Body data={entity_data.body.processed} files={props.data.files} />
							</div>
						</div>
						{organizer.length > 0 && (
							<div className='pt-4 pb-16'>
								<div className='font-body text-left text-xl mt-10 mb-5 font-semibold'>
									Event Organizer
								</div>

								{organizer.map((person) => {
									return <BusinessCard data={person} key={person.id} />;
								})}
							</div>
						)}
					</div>
				</div>
				{props.content && (
					<div className='relative'>
						<Content content={props.content.content} />
					</div>
				)}
			</div>
		</div>
	);
};

export default EventDetail;
