import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import EventDetail from '../../components/content_type/Event/EventDetail';
import { stripHtml } from 'string-strip-html';

const EventPage = ({ data }) => {
	const edge = data.eventItem.edges;
	const noHtmlDesc = (html) => {
		return stripHtml(html).result;
	};

	return (
		<Layout>
			<>
				{edge.map((item) => (
					<div>
						<Seo
							title={item.node.label}
							description={noHtmlDesc(item.node.r.entity_id.body.processed)}
							summary={item.node.r.entity_id.summary}
							keywords={[`event`]}
							image={item.node.r.entity_id.r.media.r.image.uri.url}
							url={process.env.SITE_URL + item.node.r.entity_id.path.alias + "/"}
						/>
						<EventDetail
							key={item.node.drupal_internal__id}
							data={data}
							lodge={data.lodge}
							content={item.node.r.entity_id.r}
						/>
					</div>
				))}
			</>
		</Layout>
	);
};

export default EventPage;

export const query = graphql`
					query eventPageQuery($slug: Int, $gid: Int) {
						eventItem: allGroupContentLodgeGroupNodeEvent(
							filter: { drupal_internal__id: { eq: $slug } }
						) {
							edges {
								node {
									...eventFragment
								}
							}
						}
						files: allFileFile {
							edges {
								node {
									style: image_style_uri {
										...imageStyleFragment
									}
									drupal_id
								}
							}
						}
						lodge: groupLodge(drupal_internal__id: { eq: $gid }) {
							...lodgeFragment
						}
					}
				`;
