import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import JsonLd from './JsonLd';

export class MetaComponent extends Component {
	render() {
		return (
			<div className='wrapper'>
				<MetaTags>
					{this.props.jsonLd && <JsonLd data={this.props.jsonLd} />}
				</MetaTags>
			</div>
		);
	}
}

export default MetaComponent;
